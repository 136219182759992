import React from 'react';
import {
  withStyles,
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Button,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import dayjs from 'dayjs';

import CourseStatusMark from './courseStatusMark';
import { useHistory } from "react-router-dom";
import { ROUTE_PATH } from "@constants/index";
import { copyCourse } from '@modules/courseInfo/createCourse/service';

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxHeight: 800,
    overflowY: 'auto',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
  },
  tableListRow: {
    root: {
      borderBottom: 'none',
    },
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: 100,
      height: 119,
      borderBottomLeftRadius: 8,
      borderTopLeftRadius: 8,
      borderRadius: 0
    },
  },
  copyButton: {
    height: 80,
    width: 120,
    borderLeft: '1px solid #DDDDDD',
    display: 'block',
    paddingLeft: 30,
    paddingRight: 30,
  },
  copyText: {
    fontSize: 12,
  }
});

const StyledTableCell = withStyles(() => ({
  root: {
    padding: '16px',
    borderBottom: 'none',
    verticalAlign: 'middle',

  },
  head: {
    backgroundColor: '#FFF',
    color: '#999',
    '&:first-child': {
      paddingLeft: 30,
    },
  },
  body: {
    fontSize: 14,
    backgroundColor: '#F6F6F6',
    '&:first-child': {
      borderBottomLeftRadius: '8px',
      borderTopLeftRadius: '8px',
      padding: 0
    },
    '&:last-child': {
      borderBottomRightRadius: '8px',
      borderTopRightRadius: '8px',
    },
  },
}))(TableCell);

const tableHeader = {
  avatar: '封面',
  title: '标题',
  createdTime: '创建时间',
  userName: '创建人',
  description: '课程描述',
  deadline: '截止日期',
  extra: ''
};

const Body = ({ courseList }) => {
  const classes = useStyles();
  const tableField = Object.keys(tableHeader);
  const history = useHistory();

  const handleClickCourses = (courseId) => {
    history.push(`${ROUTE_PATH.VIEW_COURSE}/${courseId}`);
  };

  const handleCopyCourses = async (id) => {
    const newCourseId = await copyCourse(id);
    handleClickCourses(newCourseId);
  };

  return (
    <div className={classes.container}>
      <Table className={classes.table} stickyHeader>
        <TableHead >
          <TableRow >
            {tableField.map((key) => (
              <StyledTableCell key={key}>
                <Typography>{tableHeader[key]}</Typography>
              </StyledTableCell>
            ))}
          </TableRow>
          </TableHead>

          <TableBody>
            {courseList && courseList.map((item, index) => (
              <TableRow className={classes.tableListRow} key={`table-row-${index + 1}`} >
                <StyledTableCell>
                  <Button>
                    <Avatar
                      onClick={() => handleClickCourses(item.id)}
                      src={item.coverThumbnail || '/defaultCoverImage.png'}
                      className={classes.avatar}
                    />
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <CourseStatusMark status={item.courseStatus}/>
                  {item.courseName.substring(0, 10)}
                  <div/>
                  {item.courseName.substring(10, item.courseName.length)}
                </StyledTableCell>
                <StyledTableCell>
                  {dayjs(item.createdAt).format('YYYY-MM-DD')}
                  <div/>
                  {dayjs(item.createdAt).format('HH:mm:ss')}
                </StyledTableCell>
                <StyledTableCell>
                  {item.userName}
                </StyledTableCell>
                <StyledTableCell>
                  {item.description && item.description.length >= 18
                    ? item.description.substring(0, 17)+'...'
                    : item.description
                  }
                </StyledTableCell>
                <StyledTableCell>
                  {item.endTime ? dayjs(item.endTime).format('YYYY-MM-DD') : '无'}
                  <div/>
                  {item.endTime ? dayjs(item.endTime).format('HH:mm:ss') : null}
                </StyledTableCell>
                <StyledTableCell>
                  <Button className={classes.copyButton} onClick={() => handleCopyCourses(item.id)}>
                    <FileCopyIcon/>
                    <Typography className={classes.copyText} >复制</Typography>
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </div>
  );
};

export default Body;
