/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import DefaultLayout from '@components/layout';
import { ROUTE_PATH } from '@constants/index';
import { help } from '@util';

export default function RouteGuard({ page, ...rest }) {
  const jwtToken = help.getStorageToken();
  const isLoggedIn = !!jwtToken;
  const Layout = page.layout || DefaultLayout;

  if (!page.public && !isLoggedIn) {
    return <Redirect to={ROUTE_PATH.LOGIN} />;
  }

  return (
    <Route
      {...rest}
      exact
      path={page.path}
      render={() => <Layout sideNav={page.SideNav} mainContent={page.MainContent} />}
    />
  );
}
