import React, { useState } from 'react';
import { makeStyles, Checkbox, Avatar, List, ListItem, IconButton } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import help from '@util/help';

const useStyles = makeStyles({
  container: {
    overflow: 'auto',
  },
  listItem: {
    '&.MuiListItem-gutters': {
      padding: '8px 0 8px 8px',
    },
    minWidth: 340,
    borderBottom: '1px solid #eeeeee',
    position: 'relative',
  },
  avatar: {
    '&.MuiAvatar-root': {
      margin: '3px 5px 3px 10px',
      borderRadius: 5,
    }
  },
  listItemTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
    flex: 1,
  },
  name: {
    fontSize: 16,
    fontWeight: 400
  },
  position: {
    fontSize: 12,
    color: '#c4c4c4',
    marginTop: 2,
  },
  goBackIcon: {
    position: 'relative',
  }
});

// todo put in file
const MemberItem = ({member, onChange}) => {
  const classes = useStyles();

  return <>
    <Checkbox
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<CheckCircleOutlineIcon />}
      color='primary'
      onChange={onChange}
      checked={!!member.selected}
      disabled={member.selectDisable}
    />
    <Avatar
      className={classes.avatar}
      alt={member.name}
      src={help.wechatThumbnail(member.avatar)}
    />
    <div className={classes.listItemTextContainer}>
      <span className={classes.name}>{member.name}{member.fullName&&`(${member.fullName})`}</span>
      <span className={classes.position}>{member.position}</span>
    </div>
  </>;
};

// todo put in file
const GroupItem = ({group, onClick, onChange, checked}) => {
  const classes = useStyles();

  return (
    <>
      <Checkbox
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<CheckCircleOutlineIcon />}
        color='primary'
        onChange={onChange}
        checked={!!checked}
      />
      <Avatar
        className={classes.avatar}
        alt={group.name}
        src="https://cdn4.iconfinder.com/data/icons/32px_Mantra/PNG/Finder.png"
      />
      <div className={classes.listItemTextContainer}>
        <span className={classes.name}>{group.name}</span>
      </div>
      <IconButton onClick={onClick}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </>
  );
};


const MemberList = ({ userList, refreshUserList, departmentList, setDepartmentList }) => {
  const classes = useStyles();

  const [departmentId, setDepartmentId] = useState(1);
  const [backDepartmentId, setBackDepartmentId] = useState(0);

  const refreshDepartmentList = () => {
    setDepartmentList([...departmentList]);
  };

  const handleGoBackGroup = () => {
    setDepartmentId(backDepartmentId);
    setBackDepartmentId(departmentList.find(department => department.id === backDepartmentId).parentid);
  };

  const findGroupsInGroup = (id, groups = []) => {
    groups.push(id);
    const childGroups = departmentList.filter(department => department.parentid === id).map(department => department.id);
    childGroups.forEach(childId => findGroupsInGroup(childId, groups));
    return groups;
  };

  const findUsersInGroup = (id) => {
    const groups = findGroupsInGroup(id);
    return userList.filter(user => user.department.some(id => groups.includes(id)));
  };

  const searchGroupAllSelected = (id) => {
    const users = findUsersInGroup(id);
    return users.every(user => user.selected);
  };

  const setGroupAllSelected = (id, state) => {
    const users = findUsersInGroup(id);
    users.forEach(user => {
      if(user.selectDisable) return;
      user.selected = state;
    });
    refreshDepartmentList();
  };

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.goBackIcon}
        disabled={backDepartmentId === 0}
        onClick={handleGoBackGroup}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <List>
        {/*部门*/}
        { departmentList.filter(department => department.parentid === departmentId).map((department) => (
          <ListItem button className={classes.listItem} key={department.id}>
            <GroupItem
              group={department}
              onClick={() => {
                setDepartmentId(department.id);
                setBackDepartmentId(department.parentid);
              }}
              checked={searchGroupAllSelected(department.id)}
              onChange={(event) => {
                setGroupAllSelected(department.id, event.target.checked);
                refreshUserList();
              }}
            />
          </ListItem>
        ))}
        {/*员工*/}
        { userList.filter(user => user.department.includes(departmentId)).map((member) => (
          <ListItem button className={classes.listItem} key={member.userid}>
            <MemberItem
              member={member}
              onChange={(event) => {
                member.selected = event.target.checked;
                refreshUserList();
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MemberList;
