import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: 100,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 29,
    whiteSpace: 'nowrap',
  },
  button: {
    fontFamily: 'PingFang SC',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
  },
  buttonOnSort: {
    color: '#999999',
  },
});

const SortButton = ({
  text,
  disabled,
  setSort,
}) => {
  const classes = useStyles();
  const [orderDirection, setOrderDirection] = useState('desc');

  const sortIcon = {
    asc: <ArrowUpwardIcon />,
    desc: <ArrowDownwardIcon />,
  };

  const onSort = () => {
    const direction = orderDirection === 'asc' ? 'desc' : 'asc';
    setOrderDirection(direction);
    setSort(direction);
  };

  return (
    <div className={classes.container}>
      <Button
        onClick={onSort}
        className={disabled ? `${classes.button} ${classes.buttonOnSort}` : classes.button}
      >
        {text}
        {disabled ? null : sortIcon[orderDirection]}
      </Button>
    </div>
  );
};

SortButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SortButton;
