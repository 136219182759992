import { http } from '@util/index';
import { API } from '@constants/index';
import { createCourse } from "@modules/home/service";
import { cleanMemberStatus } from "@modules/courseInfo/pickMember/service";
import lodash from 'lodash';

import axios from 'axios';

const getCourseInfo = async (courseId) => {
  const result = await http.get(`${API.COURSE_DETAILS}/${courseId}`);
  return result;
};

const replaceDomain = (url) => {
  return url.replace((new URL(url)).origin, '');
};

const cdn = (url) => {
  if (!url || !url.includes('amazonaws.com.cn')) return url;
  return replaceDomain(url);
};

const filesUseCdn = (files) => {
  if (!Array.isArray(files)) return files;
  return files.map(file => {
    file.url = cdn(file.url);
    if (file.thumbnailUrl) file.thumbnailUrl = cdn(file.thumbnailUrl);
    if (file.isMultipleFiles) file.images = file.images.map(cdn);
    return file;
  });
};

const updateCourseInfo = async ({ courseId, courseName, description, coverImage, coverThumbnail, members, endTime, files, finishedMembers }) => {
  const cleanMembers = lodash.cloneDeep(members);
  const cdnFiles = filesUseCdn(lodash.cloneDeep(files));
  cleanMemberStatus(cleanMembers);
  await http.put(API.COURSE, { courseId,
    courseName,
    description,
    coverImage: cdn(coverImage),
    coverThumbnail: cdn(coverThumbnail),
    members: cleanMembers, endTime,
    files: cdnFiles,
    finishedMembers
  });
};

const deleteCourse = async (courseId) => {
  const result = await http.delete(API.COURSE, { data: { courseId } });
  return result;
};

const deployedCourse = async (courseId) => {
  await http.put(API.COURSE_DEPLOY, { courseId });
};

const copyCourse = async (courseId) => {
  const source = await getCourseInfo(courseId);
  const newCourseId = await createCourse(source.courseName);
  await updateCourseInfo({...source, courseId: newCourseId, files:[], coverImage: '', coverThumbnail: undefined, finishedMembers: []});
  return newCourseId;
};

const smartGetCourseInfo = async (courseId) => {
  if (window._courseEditPageSharedState && window._courseEditPageSharedState.course) {
    const course = window._courseEditPageSharedState.course;
    // console.log('service',course);
    return course;
  }
  const course = await getCourseInfo(courseId);
  return course;
};

const wechatNotification = async (members, message) => {
  await http.post(API.WECHAT_NOTIFICATION, { members, message });
};

const getS3SignUrl = async ({id, fileName, courseName}) => {
  const { signedUrl } = await http.put(API.S3_SIGN_URL, {id, courseName, filename: fileName});
  return signedUrl;
};

const uploadFileToS3 = async ({ blob, signedUrl }) => {
  await axios.put(signedUrl, blob, {
    headers: {
      "content-type": blob.type,
      'x-amz-acl': 'public-read',
    }
  });
};

const deleteFileFromS3 = async (deleteKeys) => {
  await http.delete(API.S3_SIGN_URL, { data: {keys: deleteKeys.map(decodeURI)} });
};

const cutPdf = async ({ fileName, fileUrl, courseId }) => {
  const images = await http.post(API.CUT_PDF, { fileName, fileUrl, courseId });
  return images;
};


export {
  getCourseInfo,
  updateCourseInfo,
  deleteCourse,
  deployedCourse,
  copyCourse,
  smartGetCourseInfo,
  getS3SignUrl,
  uploadFileToS3,
  deleteFileFromS3,
  cutPdf,
  wechatNotification,
};
