import SideNav from './sideNav';
import MainContent from './mainContent';
import { ROUTE_PATH } from '@constants/index';

export default {
  public: false,
  path: `${ROUTE_PATH.PICK_MEMBER}/:id`,
  SideNav,
  MainContent,
};
