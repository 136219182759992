import React from 'react';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
 } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: 60,
    borderTop: 1,
    borderBottom: 1,
    '&.MuiListItem-gutters': {
      padding: 0
    },
    '&.MuiTypography-body1': {
      fontWeight: 500
    }
  },
  separator: {
    height: 1,
    width: '90%',
    backgroundColor: '#EEEEEE',
  },
  members: {
    position: 'relative',
    right: 25
  },
  mark:{
    height: '100%',
    width: 8,
    marginRight: 20
  },
  selectedMark: {
    backgroundColor: '#000000',
  },
  selectedIcon: {
    color: '#000000',
  },
  icon: {
    color: '#999999',
  },
});

const TabMenu = ({ icon, text, members, selected }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem button key={text} className={classes.container} >
        <div
          className={selected
          ? `${classes.mark} ${classes.selectedMark}`
          : classes.mark}
        />
        <ListItemIcon className={selected ? classes.selectedIcon : classes.icon}>{icon}</ListItemIcon>
        <ListItemText className={selected ? classes.selectedIcon : classes.icon} primary={text} />
          { text === '选择成员'
            && <Typography
                className={selected ? `${classes.selectedIcon} ${classes.members}` : `${classes.icon} ${classes.members}`}
                >
              {members}
              </Typography>
          }
      </ListItem>
      <div className={classes.separator} />
    </>
  );
};

export default TabMenu;
