import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import EditingIcon from '../components/editingIcon';
import DoneIcon from '@material-ui/icons/Done';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import {Card, FillFlexSpace} from '@components/common';
import MemberList from '../components/memberList';
import CheckedMemberList from '../components/checkedMemberList';
import {reDeployCourse, smartGetWechatMembers} from './service';
import {load} from '@util/index';

import {
  copyCourse,
  deployedCourse,
  smartGetCourseInfo,
  updateCourseInfo,
  wechatNotification,
} from "@modules/courseInfo/createCourse/service";
import {useHistory, useParams} from "react-router-dom";
import {ROUTE_PATH, MESSAGE} from "@constants/index";

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  memberListContainer: {
    padding: '40px 25px',
    alignItems: 'normal',
    boxSizing: 'border-box',
    height: '88vh',
    minWidth: 0,
    width: 494,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center'
  },
  description: {
    color: '#666666',
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    margin: '16px 0 31px 0'
  },
  memberListTitle: {
    fontSize: 16,
    fontWeight: 500,
    margin: '22px 0 22px 14px',
    display: 'flex',
    alignItems: 'center',
  },
  memberListTitleText: {
    padding: '12px 0',
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#EEEEEE'
  },
  pickMemberContainer: {
    minWidth: 368,
    marginLeft: 20,
    boxSizing: 'border-box',
    height: '88vh',
    padding: '40px 20px',
  },
  pickMemberTitle: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 10
  },
});

let course;
let wechatUsers;

export default () => {
  const classes = useStyles();
  const [courseStatus, setCourseStatus] = useState('done');
  const [userList, setUserList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [editing, setEditing] = useState(false);
  const [leftSelectedUser, setLeftSelectedUser] = useState(new Set());

  const setCanReDeploy = useRef(() => {});
  const setCanDeploy = useRef(() => {});
  const setMemberNum = useRef(() => {});

  const { id } = useParams();
  const history = useHistory();

  const getSelectedUsers = () => (courseStatus === 'done')
    ? userList
    : userList.filter(user => user.selected === true && user.selectDisable !== true);

  const getDisabledUsers = () => {
    return userList.filter(user => user.selected === true && user.selectDisable === true);
  };

  const refreshCanReDeployStatus = (courseStatus) => {
    setCanReDeploy.current(!!(courseStatus === 'deployed'
        && wechatUsers
        && wechatUsers.userlist.filter(user => user.selected === true && user.selectDisable !== true).length > 0
    ));
  };

  const refreshCanDeployStatus = (courseStatus) => {
    setCanDeploy.current(!!(courseStatus === 'draft'
        && course.description.length !== 0
        && wechatUsers
        && wechatUsers.userlist.filter(user => user.selected === true).length > 0
    ));
  };

  const refreshUserList = () => {
    setUserList([...userList]);
    setMemberNum.current(wechatUsers.userlist.filter(user => user.selected === true).length);
    refreshCanReDeployStatus(courseStatus);
    refreshCanDeployStatus(courseStatus);
  };
  const clearLeftSelectUser = () => {
    setLeftSelectedUser(new Set());
  };

  const handleEditing = () => {
    Array.from(leftSelectedUser).forEach(id => {
      userList.find(user => user.userid === id).selected = false;
    });
    clearLeftSelectUser();
    refreshUserList();
  };

  const handleSaveCourse = async () => {
    load.open();
    await updateCourseInfo({
      courseId: id,
      courseName: course.courseName,
      description: course.description,
      coverImage: undefined,
      members: userList.filter(user => user.selected),
      endTime: course.endTime,
      files: course.files.map(file => ({
        id: file.id,
        name: file.name,
        type: file.type,
        isMultipleFiles: file.isMultipleFiles,
        images: file.images,
        url: file.url,
        key: file.key,
        thumbnailUrl: file.thumbnailUrl,
        thumbnailKey: file.thumbnailKey,
        deleteKeys: file.deleteKeys,
      }))
    });
    load.close();
  };

  const handlePublishCourse = async () => {
    load.open();
    await handleSaveCourse();
    await deployedCourse(id);
    await wechatNotification(userList.filter(user => user.selected).map(user => user.userid),MESSAGE.COURSE_DEPLOY);
    load.close();
    history.push(ROUTE_PATH.HOME);
  };

  const handleGoCourse = (courseId) => {
    history.push(`${ROUTE_PATH.VIEW_COURSE}/${courseId}`);
  };

  const handleCopyCourse = async () => {
    load.open();
    const newId = await copyCourse(id);
    load.close();
    handleGoCourse(newId);
    window.location.reload();
  };

  const handleReDeployCourse = async () => {
    load.open();
    const users = wechatUsers.userlist.filter(user => user.selected);
    await reDeployCourse(id, users);
    await wechatNotification(wechatUsers.userlist.filter(
        user => user.selected === true
            && user.selectDisable !== true
    ).map(user => user.userid),MESSAGE.COURSE_DEPLOY);
    load.close();
    history.push(ROUTE_PATH.HOME);
  };

  const handleSaveCourseSharedState = () => {
    window._courseEditPageSharedState = {
      course: {...course, ...{
          members: courseStatus === 'draft' ? userList.filter(user => user.selected) : [...course.members]
        }
      },
      wechatUsers: {...wechatUsers}
    };
    setTimeout(() => delete(window._courseEditPageSharedState), 0);
  };

  const currentFunctions = useRef({});
  currentFunctions.current = {
    handlePublishCourse,
    handleSaveCourse,
    handleCopyCourse,
    handleSaveCourseSharedState,
    handleReDeployCourse,
  };


  useEffect(() => {
      (async () => {
        course = await smartGetCourseInfo(id);

        const sideBar = window._courseViewPageSideBarController;
        if ( sideBar ) {
          const { setCourseStatus } = sideBar;
          setCanReDeploy.current = sideBar.setCanReDeploy;
          setCanDeploy.current = sideBar.setCanDeploy;
          setMemberNum.current = sideBar.setMemberNum;
          setCourseStatus(course.courseStatus);
          sideBar.handlePublishCourse = (...args) => currentFunctions.current.handlePublishCourse(...args);
          sideBar.handleSaveCourse = (...args) => currentFunctions.current.handleSaveCourse(...args);
          sideBar.handleCopyCourse = (...args) => currentFunctions.current.handleCopyCourse(...args);
          sideBar.switchTabHook = (...args) => currentFunctions.current.handleSaveCourseSharedState(...args);
          sideBar.handleReDeploy = (...args) => currentFunctions.current.handleReDeployCourse(...args);
        }

        const { courseStatus, members } = course;

        if (courseStatus !== 'done') {

          load.open();
          wechatUsers = await smartGetWechatMembers();
          const {department, userlist} = wechatUsers;
          load.close();

          const users = userlist;
          const departments = department;

          members.forEach(member => {
            let user = users.find(user => user.userid === member.userid);
            user.selected = true;
            if (courseStatus === 'deployed') user.selectDisable = true;
          });
          setCourseStatus(courseStatus);
          setUserList(users);
          setMemberNum.current(wechatUsers.userlist.filter(user => user.selected === true).length);
          refreshCanReDeployStatus(courseStatus);
          refreshCanDeployStatus(courseStatus);
          setDepartmentList(departments);
        } else {
          wechatUsers = undefined;
          const users = members;
          setCourseStatus(courseStatus);
          setUserList(users);
          setMemberNum.current(course.members.length);
          refreshCanReDeployStatus(courseStatus);
          refreshCanDeployStatus(courseStatus);
        }
      })();
  }, []);

  const renderEditingIcons = () => {
    if (courseStatus==='done') return;
    return editing
    ? <>
        <EditingIcon
          text="删除"
          Icon={DeleteForeverOutlinedIcon}
          onClick={handleEditing}
        />
        <EditingIcon
          text="完成"
          Icon={DoneIcon}
          onClick={() => {
            setEditing(false);
            clearLeftSelectUser();
          }}
        />
      </>
    : <EditingIcon
        text="编辑"
        Icon={BorderColorIcon}
        onClick={() => setEditing(true)}
      />;
  };


  return (
    <div className={classes.container}>
      <Card classes={classes.memberListContainer}>
        <span className={classes.title}>成员列表</span>
        {course && course.courseStatus !== 'done' ? <p className={classes.description}>请从右侧勾选需要完成课程的成员<br/>添加至成员列表</p> :
            <p className={classes.description}><br/></p>
        }
        <div className={classes.separator}/>

        <div className={classes.memberListTitle}>
          <div className={classes.memberListTitleText}>
            {`${courseStatus==='deployed' ? '新添加' : '共'}${getSelectedUsers().length}人`}
          </div>
          <FillFlexSpace/>
           { (getSelectedUsers().length > 0) && renderEditingIcons() }
        </div>

        {getSelectedUsers().length > 0 &&
          <MemberList
            userList={getSelectedUsers()}
            hasCheckedBox={editing}
            selectedUser={leftSelectedUser}
            setSelectedUser={setLeftSelectedUser}
          />
        }
        {courseStatus==='deployed'
          && (
            <>
              <div className={classes.separator}/>
              <div className={classes.memberListTitle}>
                已发送{getDisabledUsers().length}人
              </div>
              <MemberList userList={getDisabledUsers()}/>
            </>
          )
        }
      </Card>

      {courseStatus!=='done'
        && (
          <Card classes={classes.pickMemberContainer}>
            <div className={classes.pickMemberTitle}>选择成员</div>
            <CheckedMemberList
              userList={userList}
              departmentList={departmentList}
              refreshUserList={refreshUserList}
              setDepartmentList={setDepartmentList}
            />
          </Card>
        )
      }
    </div>
  );
};
