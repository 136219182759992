import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText
 } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useHistory, useLocation, useParams } from "react-router-dom";

import TabMenu from './tabMenu';
import { OperationButton, OperationModal } from '@components/common';
import { ROUTE_PATH } from '@constants/index';
import { deleteCourse } from "@modules/courseInfo/createCourse/service";

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FDFDFD'
  },
  backContainer: {
    position: 'relative',
    right: 45,
    display: 'flex',
    alignItems: 'center',
    height: 100,
  },
  icon: {
    color: '#000'
  },
  // to be implemented
  text: {
    '&.MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  separator: {
    height: 1,
    width: '90%',
    backgroundColor: '#eeeeee'
  },
  publishButtonRoot: {
    marginTop: 200,
    backgroundColor: "#000"
  },
  publishedButtonRoot: {
    marginTop: 200,
    backgroundColor: '#eeeeee',
  },
  rePublishedButtonRoot: {
    marginTop: 200,
  },
  publishedButtonLabel:{
    color: '#999999'
  },
  duplicateButtonRoot: {
    border: '1px solid #000000',
    backgroundColor: '#FDFDFD',
    marginTop: 16,
  },
  duplicateButtonLabel:{
    color: '#000'
  },
  deleteContainer: {
    marginTop: 34,
  },
  deleteIcon: {
    minWidth: 0,
    color: '#000',
  },
  deleteTextContainer:{
    borderBottom: '1px solid #000000',
  },
  tabMenuContainer: {
    width: '100%'
  }
});

const deleteMessage = {
  draft: <span>删除课程后，所有信息将被删除，请在以下输入框内填写“<span style={{color: 'red'}}>确认删除</span>”，并点击确认按钮。</span>
};

let sideBarController;

const CourseInfoPanel = () => {
  const classes = useStyles();

  const handlePublishCourse = () => {
    sideBarController.handlePublishCourse();
  };

  const handleSaveCourse = () => {
    sideBarController.handleSaveCourse();
  };

  const handleDuplicateCourse = () => {
    sideBarController.handleCopyCourse();
  };

  const handleSwitchTab = (router) => {
    sideBarController.switchTabHook();
    history.push(router);
  };

  const handleReDeploy = () => {
    sideBarController.handleReDeploy();
  };


  const history = useHistory();
  const location = useLocation();
  const [deleteCourseModalOpened, setDeleteCourseModalOpened] = useState(false);
  const [makeSureDeleteCourse, setMakeSureDeleteCourse] = useState('');
  const [deleteCourseMessage, setDeleteCourseMessage] = useState('');
  const [courseStatus, setCourseStatus] = useState('draft');
  const [canDeploy, setCanDeploy] = useState(false);
  const [canReDeploy, setCanReDeploy] = useState(false);
  const [memberNum, setMemberNum] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    sideBarController = {
      setCourseStatus,
      setCanReDeploy,
      setCanDeploy,
      setMemberNum,
      handlePublishCourse: () => {},
      handleSaveCourse: () => {},
      handleCopyCourse: () => {},
      switchTabHook: () => {},
      handleReDeploy: () => {},
    };
    window._courseViewPageSideBarController = sideBarController;
    }, []);

  const goBackHome = () => {
    history.push(ROUTE_PATH.HOME);
  };

  const handleCloseModal = () => {
    setDeleteCourseModalOpened(false);
    setMakeSureDeleteCourse('');
  };

  const handleDeleteCourseModal = () => {
    setDeleteCourseMessage(deleteMessage.draft);
    setDeleteCourseModalOpened(true);
  };

  const handleDeleteCourse = async () => {
    await deleteCourse(id);
    goBackHome();
  };

  return (
    <div className={classes.container}>
      <div className={classes.backContainer}>
        <ListItem button onClick={() => history.push(ROUTE_PATH.HOME)}>
          <ListItemIcon className={classes.icon}><KeyboardBackspaceIcon/></ListItemIcon>
          <ListItemText className={classes.text} primary='返回控制面板'/>
        </ListItem>
      </div>
      <div className={classes.separator} />

      <div className={classes.tabMenuContainer} onClick={() => handleSwitchTab(`${ROUTE_PATH.VIEW_COURSE}/${id}`)}>
        <TabMenu icon={<WidgetsOutlinedIcon/>} text='创建课程' selected={location.pathname.startsWith(ROUTE_PATH.VIEW_COURSE)}/>
      </div>
      <div className={classes.tabMenuContainer} onClick={() => handleSwitchTab(`${ROUTE_PATH.PICK_MEMBER}/${id}`)}>
        <TabMenu icon={<PeopleOutlineIcon/>} text='选择成员' members={memberNum} selected={location.pathname.startsWith(ROUTE_PATH.PICK_MEMBER)}/>
      </div>

      { courseStatus !== 'draft'
        ? <>
            <OperationButton
              disabled={!canReDeploy}
              labelStyle={canReDeploy ? null : classes.publishedButtonLabel }
              rootStyle={canReDeploy ? classes.rePublishedButtonRoot : classes.publishedButtonRoot}
              onClick={handleReDeploy}
            >
              { canReDeploy ? '再次发布' : (courseStatus === 'done' ? '已完成' : '已发布') }
            </OperationButton>
            <OperationButton rootStyle={classes.duplicateButtonRoot} labelStyle={classes.duplicateButtonLabel} onClick={handleDuplicateCourse}>复制课程</OperationButton>
          </>
        : <>
            <OperationButton
              disabled={!canDeploy}
              rootStyle={classes.publishButtonRoot}
              onClick={handlePublishCourse}
            >
              发布
            </OperationButton>
            <OperationButton rootStyle={classes.duplicateButtonRoot} labelStyle={classes.duplicateButtonLabel} onClick={handleSaveCourse}>保存草稿</OperationButton>
          </>
      }

      <div className={classes.deleteContainer}>
        <ListItem button onClick={handleDeleteCourseModal}>
          <ListItemIcon className={classes.deleteIcon} ><DeleteForeverOutlinedIcon/></ListItemIcon>
          <div className={classes.deleteTextContainer}>
            <ListItemText primary='删除课程' />
          </div>
        </ListItem>
        <OperationModal
          isOpen={deleteCourseModalOpened}
          title="删除课程"
          description={deleteCourseMessage}
          handleConfirmFunc={handleDeleteCourse}
          handleCancelFunc={handleCloseModal}
          DisabledConfirmButton={makeSureDeleteCourse !== '确认删除'}
          inputValue={makeSureDeleteCourse}
          setInputValue={setMakeSureDeleteCourse}
        />
      </div>
    </div>
  );
};

export default CourseInfoPanel;
