import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import {
  createMuiTheme,
  ThemeProvider,
  TextField,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingTop: '50px',
  },
  selectedContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'PingFang SC',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#666666',
  },
  itemRoot: {
    borderColor: '#CCCCCC',
    backgroundColor: '#fff',
  },
  itemSelected: {
    color: '#fff',
    '&.MuiPaginationItem-page': {
      backgroundColor: '#333333',
    },
  },
  goButton: {
    borderRadius: '4px',
    minWidth: '36px',
    height: '36px',
    fontSize: '12px',
    marginLeft: '16px',
    color: '#fff',
    backgroundColor: '#000',
    '&.Mui-disabled': {
      color: '#999999',
      backgroundColor: '#EEEEEE',
    },
  },
});

const textFieldTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        marginLeft: '10px',
        marginRight: '10px',
        width: '70px',
        height: '36px',
      },
    },
  },
});

const TablePagination = ({
  onPageChange, currentPage, dataAmount, pageSize,
}) => {
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState(1);
  const [editingPage, setEditingPage] = useState(selectedPage);
  const [disableGoButton, setDisableGoButton] = useState(false);

  const totalPages = dataAmount / pageSize > 1 ? Math.ceil(dataAmount / pageSize) : 1;

  useEffect(() => {
    setSelectedPage(currentPage);
    setEditingPage(currentPage);
  }, [currentPage]);

  const handleGoToPage = (page) => {
    setSelectedPage(editingPage);
    onPageChange(page);
  };

  const handleEditingPage = (value) => {
    const page = +value;
    if ((page === 0 && value.length > 0) || page < 0 || page > totalPages) return;
    setEditingPage(page);

    if (page === 0) {
      setDisableGoButton(true);
      return;
    }
    setDisableGoButton(false);
  };

  return (
    <div className={classes.container}>
      <Pagination
        size="large"
        count={totalPages}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        page={selectedPage}
        onChange={(event, page) => {
          setEditingPage(page);
          setSelectedPage(page);
          onPageChange(page);
        }}
        renderItem={(item) => {
          const newItem = {
            ...item,
            selected: (item.type === 'page') && (item.page === Number(selectedPage)),
          };
          return (
            <PaginationItem
              classes={{
                root: classes.itemRoot,
                selected: classes.itemSelected,
              }}
              /*eslint-disable*/
              {...newItem}
            />
          );
        }}
      />

      <div className={classes.selectedContainer}>
        跳转到:
        <ThemeProvider theme={textFieldTheme}>
          <TextField
            value={editingPage}
            onChange={(event) => handleEditingPage(event.target.value)}
            size="small"
            variant="outlined"
            type="number"
            classes={{root: classes.inputFieldRoot}}
          />
        </ThemeProvider>
        /{totalPages} 页
        <Button
          disabled={disableGoButton}
          className={classes.goButton} 
          onClick={() => handleGoToPage(editingPage)}
        >
          Go
        </Button>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  onPageChange: PropTypes.func.isRequired, 
  currentPage: PropTypes.number.isRequired,
  dataAmount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default TablePagination;
