import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  IconButton,
  Typography,
  Popover,
  Button
} from '@material-ui/core';
import { ROUTE_PATH } from '@constants';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { FillFlexSpace } from '../common';
import { storage, STORAGE_KEY } from '@util';

const useStyles = makeStyles({
  headerBar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 26,
    paddingRight: 20,
  },
  topFoldIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  logo: {
    height: '100%',
    width: '100%',
    maxHeight: 30,
    maxWidth: 130,
    marginLeft: 30
  },
  userInfo: {
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: 32,
    height: 32,
    paddingRight: 8
  },
  logoutPop: {
    padding: '15px',
    height: '95px',
    width: '175px',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
  },
  logoutButton: {
    marginTop: '10px',
    borderRadius: '30px',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
});

export default function Header({ hideSideNav, setHideSideNav }) {
  const classes = useStyles();
  const history = useHistory();
  const user = storage.get(STORAGE_KEY.USERINFO, true);
  const { userName, avatar = '/avatar.png' } = user;

  const [userInfoPopAnchor, setUserInfoPopAnchor] = useState(null);

  const showUserInfoPop = (event) => {
    setUserInfoPopAnchor(event.currentTarget);
  };

  const hideUserInfoPop = () => {
    setUserInfoPopAnchor(null);
  };

  const logout = () => {
    storage.clear();
    history.push(ROUTE_PATH.LOGIN);
  };

  const toggleSidNavModel = () => {
    setHideSideNav(!hideSideNav);
  };

  const open = Boolean(userInfoPopAnchor);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container className={classes.headerBar} spacing={3}>
      <IconButton onClick={toggleSidNavModel}>
        <img className={classes.topFoldIcon} src="/Top-Fold.png" alt="Burberry" />
      </IconButton>
      <img className={classes.logo} src="/burberry_logo.svg" alt="Burberry" />
      <FillFlexSpace />
      <div className={classes.userInfo} >
        {userName && <img className={classes.avatar} src={avatar.replace('http://', 'https://')} alt="Burberry" />}
        <Typography>{userName}</Typography>
        {userName && (
          <>
            <IconButton onClick={showUserInfoPop}>
              <KeyboardArrowDownIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={userInfoPopAnchor}
              onClose={hideUserInfoPop}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.logoutPop}>
                <Typography variant="body1">
                  {`You currently login as ${userName}`}
                </Typography>
                <Button
                  className={classes.logoutButton}
                  color="primary"
                  variant="contained"
                  onClick={logout}
                >
                  Logout
              </Button>
              </div>
            </Popover>
          </>
        )}
      </div>
    </Grid>
  );
}
