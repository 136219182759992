/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Grid } from '@material-ui/core';
import Header from './header';
import SideNavContainer from './sideNavContainer';
import MainContentContainer from './mainContentContainer';

const useStyles = makeStyles({
  pageGrid: {
    margin: '0px auto',
    minWidth: '1440px',
    minHeight: '700px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerBarContainer: {
    position: 'static',
    height: '5vh',
    minHeight: '60px',
    background: 'none',
    boxShadow: 'none',
    borderBottom: '1px solid #EEEEEE',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#ffffff'
  },
  bodyContainer: {
    height: '95vh',
  },
  hidden: {
    display: 'none',
  }
});

export default function DefaultLayout({
  sideNav,
  mainContent
}) {
  const classes = useStyles();
  const [hideSideNav, setHideSideNav] = useState(false);

  return (
    <Grid container className={classes.pageGrid}>
      <AppBar className={classes.headerBarContainer}>
        <Header hideSideNav={hideSideNav} setHideSideNav={setHideSideNav} />
      </AppBar>
      <Grid item container className={classes.bodyContainer}>
        {sideNav && <SideNavContainer children={sideNav()} className={hideSideNav ? classes.hidden : null} />}
        <MainContentContainer children={mainContent()} />
      </Grid>
      <div id="loadHelper" />
      <div id="toastHelper" />
    </Grid>
  );
}
