import React from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { ROUTE_PATH } from '@constants';

const useStyles = makeStyles({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    backgroundColor: '#E5E5E5',
    overflow: 'scroll'
  },
  padding: {
    padding: '15px 90px 30px 90px',
  }
});

export default function MainContent({ children }) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid 
      item 
      className={ location.pathname.startsWith(ROUTE_PATH.LOGIN) ? classes.content : `${classes.content} ${classes.padding}`}
    >
      {children}
    </Grid>
  );
}
