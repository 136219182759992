import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import RouteGuard from './routeGuard';

import authPages from '@modules/auth';
import courseInfo from '@modules/courseInfo';
import home from '@modules/home';

const pages = [...authPages, ...courseInfo, home];

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {pages.map((page) => <RouteGuard exact path={page.path} page={page} key={page.path} />)}
      </Switch>
    </BrowserRouter>
  );
}
