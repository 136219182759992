import React, { useEffect, useState, useRef } from 'react';

import Table from './table';
import { getCourseList } from './service';
import { help, load } from '../../util';

const MainContent = () => {
  const [list, setList] = useState(null);
  const [showList, setShowList] = useState(null);
  const [query, setQuery] = useState(null);
  const [sort, setSort] = useState('descending');
  const [currentPage, setCurrentPage] = useState(1);

  window._courseListPageSetQueryFunc = setQuery;

  const currentList = useRef(() => {});
  currentList.current = () => {
    return list;
  };


  const getList = async (query) => {
    load.open();
    const { data } = await getCourseList(query);
    load.close();
    setCurrentPage(1);
    const defaultSortedList = help.sortByKey(data, 'createdAt', sort);
    setList(defaultSortedList);
    setShowList(defaultSortedList.slice(0, 5));
  };

  useEffect(() => {
    (async () => {
      await getList({...query});

      const setCourseCount = window._courseListSideNavSetCourseCount;

      const deployedCourses = currentList.current().filter(course => course.courseStatus === 'deployed').length;
      const draftCourses = currentList.current().filter(course => course.courseStatus === 'draft').length;
      const doneCourses = currentList.current().filter(course => course.courseStatus === 'done').length;
      setCourseCount({
        deployed: deployedCourses,
        draft: draftCourses,
        done: doneCourses
      });
    })();
  // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (list === null) return;
    (async () => await getList({...query}))();
    // eslint-disable-next-line
  },[query]);

  const handleSort = () => {
    let newList;
    if (sort === 'descending') {
      newList = help.sortByKey(list, 'createdAt', 'ascending');
      setSort('ascending');
    } else {
      newList = help.sortByKey(list, 'createdAt', 'descending');
      setSort('descending');
    }
    setList(newList);
    setShowList(newList.slice(5 * (currentPage-1), 5 * currentPage));
  };

  const handleChangePage = (page) => {
    setShowList(list.slice(5 * (page-1), 5 * page));
    setCurrentPage(page);
  };

  return list && showList &&
      <Table
        hasPagination
        title={`课程列表(${list.length}个)`}
        courseList={showList}
        dataAmount={list.length}
        pageSize={5}
        onSort={handleSort}
        currentPage={currentPage}
        onPageChange={handleChangePage}
      />
  ;
};

export default MainContent;
