import storage, { STORAGE_KEY } from './storage';

const getStorageToken = () => {
  const userInfo = storage.get(STORAGE_KEY.USERINFO, true);
  const { jwtToken, exp } = userInfo;
  if (!exp || exp * 1000 < Date.now()) {
    return null;
  }
  return jwtToken;
};

const sortByKey = (objectArr, key, type) => {
  const compare = () => (m, n) => {
    const a = m[key];
    const b = n[key];
    return type === 'descending' ? b - a : a - b;
  };
  return objectArr.sort(compare());
};

const fileToBlob = async (file) => {
  const res = await file.arrayBuffer();
  const ui8 = new Uint8Array(res);
  const rawData = [...ui8];
  return new Blob([new Uint8Array(rawData)], {type: file.type});
};

const resizeImage = (src, w, h) => {
  return new Promise((r) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const im = new Image();
      w = w || 0;
      h = h || 0;
      im.onload = () => {
          //为传入缩放尺寸用原尺寸
          !w && (w = im.width);
          !h && (h = im.height);
          //以长宽最大值作为最终生成图片的依据
          if(w !== im.width || h !== im.height) {
              let ratio;
              if(w > h){
                  ratio = im.width / w;
                  h = im.height / ratio;
              } else if (w === h){
                  if(im.width > im.height) {
                      ratio = im.width / w;
                      h = im.height / ratio;
                  } else {
                      ratio = im.height / h;
                      w = im.width / ratio;
                  }
              } else {
                  ratio = im.height / h;
                  w = im.width / ratio;
              }
          }
          //以传入的长宽作为最终生成图片的尺寸
          if(w > h){
              const offset = (w - h) / 2;
              canvas.width = canvas.height = w;
              ctx.drawImage(im, 0, offset, w, h);
          } else if(w < h){
              const offset = (h - w) / 2;
              canvas.width = canvas.height = h;
              ctx.drawImage(im, offset, 0, w, h);
          } else {
              canvas.width = canvas.height = h;
              ctx.drawImage(im, 0, 0, w, h);
          }
          r(canvas);
      };
      im.src = src;
  });
};

// from '1.jpg/0' to '1.jpg/100'
const wechatThumbnail = (url) => {
    if (url && url.endsWith('/0')) {
        return url.split('/').slice(0, -1).join('/') + '/100';
    } else {
        return url;
    }
};

export default {
  getStorageToken,
  sortByKey,
  fileToBlob,
  resizeImage,
  wechatThumbnail,
};
