import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@constants/index";
import { FilterButton, OperationButton, OperationModal } from '@components/common';
import { createCourse } from './service';


const useStyles = makeStyles({
  container: {
    padding: 26,
    paddingTop: 45
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px'
  },
  buttonContainer: {
    width: '100%',
    height: '100%',
    marginTop: 20,
  },
  createButtonRoot: {
    width: 208,
    height: 50,
    backgroundColor: '#000000',
    color: '#ffffff',
    borderRadius: 25,
    fontSize: 16,
  },
  createButtonLabel: {
    display: 'flex',
    flexDirection: 'row'
  }
});

let setQuery = () => {};

const SideNav = () => {
  const classes = useStyles();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [createCourseModalOpened, setCreateCourseModalOpened] = useState(false);
  const [createCourseProjectTitle, setCreateCourseProjectTitle] = useState('');
  const [courseCount, setCourseCount] = useState({
    deployed: 0,
    done: 0,
    draft: 0
  });

  useEffect(() => {
    window._courseListSideNavSetCourseCount = setCourseCount;

    setTimeout(() => {
      setQuery = window._courseListPageSetQueryFunc;
    }, 0);
  }, []);

  const history = useHistory();

  const handleCreateCourseClick = () => {
    setCreateCourseModalOpened(true);
  };

  const handleCloseModal = () => {
    setCreateCourseModalOpened(false);
    setCreateCourseProjectTitle('');
  };

  // const handleNavigateToCreatePage = () => {
  //   history.push(ROUTE_PATH.VIEW_COURSE + '?title=' + encodeURI(createCourseProjectTitle));
  // };

  const handleCreateCourse = async () => {
    const course_id = await createCourse(createCourseProjectTitle);
    if (course_id) {
      history.push(`${ROUTE_PATH.VIEW_COURSE}/${course_id}`);
    }
  };

  const handleSelect = (status) => {
    setSelectedStatus(status);
    setQuery({courseStatus: status});
  };

  return (
      <div className={classes.container}>
        <Typography className={classes.title}>控制面板</Typography>
        <div className={classes.buttonContainer}>
          <FilterButton status='deployed' amount={courseCount.deployed} selected={selectedStatus==='deployed'} currentStatus={selectedStatus} setSelected={handleSelect}/>
          <FilterButton status='done' amount={courseCount.done} selected={selectedStatus==='done'} currentStatus={selectedStatus} setSelected={handleSelect}/>
          <FilterButton status='draft' amount={courseCount.draft} selected={selectedStatus==='draft'} currentStatus={selectedStatus} setSelected={handleSelect}/>
        </div>
        <OperationButton onClick={handleCreateCourseClick}>
          <AddIcon />
          创建新课程
        </OperationButton>
        <OperationModal
          title='创建新课程'
          inputLabel='项目名称* (15字以内)'
          confirmButtonText='创建'
          handleConfirmFunc={handleCreateCourse}
          handleCancelFunc={handleCloseModal}
          isOpen={createCourseModalOpened}
          inputValue={createCourseProjectTitle}
          setInputValue={setCreateCourseProjectTitle}
          maxLength='15'
          DisabledConfirmButton={createCourseProjectTitle===''}
        />
      </div>
  );
};

export default SideNav;
