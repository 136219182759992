import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  container: {
    width: 50,
    height: 20,
    borderRadius: 20,
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CCCCCC',
  },
  doneBG: {
    backgroundColor: '#ADCFF1'
  },
  publishedBG: {
    backgroundColor: '#DAE0A4'
  },
});

const CourseStatusMark = ({ status }) => {
  const classes = useStyles();
  let statusText = '草稿';
  let className = classes.container;
  if ( status === 'done' ) {
    statusText = '已完成';
    className = `${classes.container} ${classes.doneBG}`;
  } else if( status === 'deployed' ) {
    statusText = '已发布';
    className = `${classes.container} ${classes.publishedBG}`;
  }

  return (
    <div className={className}>
      {statusText}
    </div>
  );
};


CourseStatusMark.propType = {
  status: PropTypes.string,
};
CourseStatusMark.defaultProps = {
  status: 'draft',
};

export default CourseStatusMark;