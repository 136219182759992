import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  editContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    margin: '0 5px 0 0'
  }
});

export default ({ text, Icon, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.editContainer}>
      {text}
      <IconButton className={classes.icon}>
        <Icon onClick={onClick || null} />
      </IconButton>
    </div>
  );
};