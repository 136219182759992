import React from 'react';
import Modal from "react-modal";
import { makeStyles, Grid } from '@material-ui/core';

import OperationButton from './operationButton';


const useStyles = makeStyles({
  gridContainer: {
    padding: '0 36px 36px 36px',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center'
  },
  description: {
    fontSize: 14,
    fontWeight: 400
  },
  inputContainer: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    height: 45,
    borderRadius: 10,
    border: '2px solid #cccccc',
    marginTop: 4,
  },
  buttonContainer: {
    marginTop: 57,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  cancelBtnRoot: {
    backgroundColor: '#ffffff',
    border: '1px solid #000000',
    color: '#000000'
  }
});

const MODAL_STYLES = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 510,
    borderRadius: 15,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0, 0.75)',
    zIndex: '999'
  }
};

const OperationModal = ({
  isOpen,
  title, 
  inputLabel, 
  description,
  confirmButtonText,
  cancelButtonText,
  DisabledConfirmButton,
  DisabledCancelButton,
  handleConfirmFunc,
  handleCancelFunc,
  inputValue,
  setInputValue,
  maxLength
})  => {
  const classes = useStyles();

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={()=>{}}
      onRequestClose={()=>{}}
      style={MODAL_STYLES}
      contentLabel="Example Modal"
    >
      <Grid
          container
          direction="column"
          className={classes.gridContainer}
          style={{height: "100%"}}
      >
        <p className={classes.title}>{title}</p>
        {description && <div className={classes.description}>{description}</div>}
        <div className={classes.inputContainer}>
          {inputLabel && <div className={classes.inputLabel}>{inputLabel}</div>}
          <input
            value={inputValue} 
            onChange={event => setInputValue(event.target.value)}
            className={classes.input}
            maxLength={maxLength}
          />
        </div>
        <div className={classes.buttonContainer}>
          <OperationButton onClick={handleCancelFunc} disabled={DisabledCancelButton || false} rootStyle={classes.cancelBtnRoot}>{cancelButtonText || '取消'}</OperationButton>
          <OperationButton onClick={handleConfirmFunc || null} disabled={DisabledConfirmButton || false}>{confirmButtonText || '确认'}</OperationButton>
        </div>
      </Grid>
    </Modal>
  );
};

export default OperationModal;