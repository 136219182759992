import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Files from "react-butterfiles";
import { ReactSortable } from "react-sortablejs";
import 'react-images-uploader-continued/styles.css';
import 'react-images-uploader-continued/font.css';
import { makeStyles, Button, Input, Grid, TextField, IconButton  } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Url from 'url-parse';

import { Card, DatePicker, TimePicker, OperationButton } from '@components/common';
import {
    copyCourse,
    deployedCourse,
    smartGetCourseInfo,
    updateCourseInfo,
    getS3SignUrl,
    uploadFileToS3,
    deleteFileFromS3,
    cutPdf,
    wechatNotification,
} from "./service";
import {MESSAGE, ROUTE_PATH} from "@constants/index";
import { reDeployCourse } from "@modules/courseInfo/pickMember/service";
import { load, help, toast } from '@util';

const API = {
    setProjectTitle: async function (title) {
        console.log('Project title ' + title + ' saved!');
    }
};

const useStyles = makeStyles({
    firstRowContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    firstRowCard: {
        flex: 1,
        height: 140,
        padding: '28px 10px 0 39px',
        alignItems: 'baseline',
        minWidth: 400
    },
    cardTitle: {
        fontSize: 14,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 16,
    },
    courseTitleContainer: {
        fontSize: 20,
        marginTop: 4,
        display: 'flex',
        alignItems: 'center',
    },
    editCourseTitleInput: {
        fontSize: 20,
    },
    saveButton: {
        marginLeft: 20,
        fontSize: 14,
    },
    secondRowCard: {
        marginTop: 12,
        padding: '28px 39px 47px 39px',
    },
    thirdRowCard: {
        marginTop: 11,
        padding: '39px 39px 49px 39px ',
    },
    describeText: {
        fontSize: 10,
        margin: 0,
        color: "#D3D3D3",
    },
    textMargin: {
        marginBottom: 15
    },
    operationBtnRoot:{
        marginTop: 30,
        border: '1px solid #000000',
        backgroundColor: '#ffffff',
        color: '#000000'
    },
    fileIcon: {
        width: 84,
        height: 84,
        margin: '9px 9px 0 0',
        display: "inline-block",
        overflow: "hidden",
        border: "1px solid #C4C4C4",
        backgroundSize: "cover",
        position: "relative",
        borderRadius: "2px",
    },
    fileIconLoading: {
        opacity:0.4
    },
    clearIcon:{
        position: 'absolute',
        right: '0',
        top: '0',
        '&:hover': {
            color: 'red',
        }
    },
    fileUploadArea: {
        marginTop: 40,
        width:  '100%',
        height: 120,
        border: '1px dashed #999999',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500
    },
    uploadedFiles: {
        marginTop: 26,
        width: '100%'
    },
    pickerContainer: {
        width: 342,
        display: 'flex',
        justifyContent: 'space-between'
    },
    hide: {
      display: 'none',
    },
    fileUploadText: {
        width: '100%',
        height: '120px',
        lineHeight: '120px',
        textAlign: 'center',
    },
    uploadContainer: {
        width: 100,
        height: 100,
        backgroundColor: '#EEEEEE',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: "cover",
    },
    dropFileAreaActive: {
        backgroundColor: '#C6E8FF',
    },
    dropText: {
        pointerEvents: 'none',
    },
    previewLink: {
        textDecoration: 'none',
    },
    fileUploaderPreviewButtonContainer: {
        color: "#000000",
        backgroundColor: '#FFFFFF',
        opacity: 0,
        transition: '0.3s linear',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            opacity: 0.7
          }
    },
    previewButton: {
        border: 'none',
        backgroundColor: 'transparent',
        borderStyle: 'none',
    }
});

let course;
let wechatUsers;
let files = [];

export default function CreateCourse() {
    const classes = useStyles();
    const [projectTitleEditable, setProjectTitleEditable] = useState(false);
    const [projectTitle, setProjectTitle] = useState('');
    const [descriptionText, setDescriptionText] = useState('');
    const [disableEdit, setDisableEdit] = useState(true);
    const [errorFiles, setErrorFiles] = useState([]);
    const [dropText, setDropText] = useState('或将文件拖拽至此处');
    const [date, setDate] = useState(null);
    const [coverImage, setCoverImage] = useState('/defaultCoverImage.png');
    const [coverImageThumbnail, setCoverImageThumbnail] = useState('');
    const [fakeState, setFakeState] = useState(null);

    const setFiles = (filesToSet) => {
        files = filesToSet;
        setFakeState({});
    };

    // const maxFileNumber = 50;
    const maxDescriptionLength = 50;
    const { id } = useParams();

    const history = useHistory();

    const handleSaveCourse = async () => {
        load.open();

        await updateCourseInfo({
            courseId: id,
            courseName: projectTitle,
            description: descriptionText,
            coverImage: coverImage,
            members: course.members,
            endTime: +date,
            files: files.map(file => ({
                id: file.id,
                name: file.name,
                type: file.type,
                isMultipleFiles: file.isMultipleFiles,
                images: file.images,
                url: file.url,
                key: file.key,
                thumbnailUrl: file.thumbnailUrl,
                thumbnailKey: file.thumbnailKey,
                deleteKeys: file.deleteKeys,
            }))
        });
        load.close();
    };

    const handlePublishCourse = async () => {
        load.open();
        await handleSaveCourse();
        await deployedCourse(id);
        await wechatNotification(course.members.map(member => member.userid),MESSAGE.COURSE_DEPLOY);
        load.close();
        history.push(ROUTE_PATH.HOME);
    };

    const handleGoCourse = (courseId) => {
        history.push(`${ROUTE_PATH.VIEW_COURSE}/${courseId}`);
    };

    const handleCopyCourse = async () => {
        load.open();
        const newId = await copyCourse(id);
        load.close();
        handleGoCourse(newId);
        window.location.reload();
    };

    const handleReDeployCourse = async () => {
        load.open();
        const users = wechatUsers.userlist.filter(user => user.selected);
        await reDeployCourse(id, users);
        await wechatNotification(wechatUsers.userlist.filter(
            user => user.selected === true
                && user.selectDisable !== true
        ).map(user => user.userid),MESSAGE.COURSE_DEPLOY);
        load.close();
        history.push(ROUTE_PATH.HOME);
    };

    const handleSaveCourseSharedState = () => {
        window._courseEditPageSharedState = {
            course: {...course, ...{
                    courseName: projectTitle,
                    description: descriptionText,
                    coverImage,
                    endTime: +date,
                    files: files.map(file => ({
                        id: file.id,
                        name: file.name,
                        type: file.type,
                        isMultipleFiles: file.isMultipleFiles,
                        images: file.images,
                        url: file.url,
                        key: file.key,
                        thumbnail: file.thumbnailUrl,
                        thumbnailUrl: file.thumbnailUrl,
                        thumbnailKey: file.thumbnailKey,
                        deleteKeys: file.deleteKeys,
                    }))
            }},
            wechatUsers: wechatUsers
        };
        setTimeout(() => delete(window._courseEditPageSharedState), 0);
    };

    const currentFunctions = useRef({});
    const setCanDeployRef = useRef(() => {});
    currentFunctions.current = {
        handlePublishCourse,
        handleSaveCourse,
        handleCopyCourse,
        handleSaveCourseSharedState,
        handleReDeployCourse,
    };

    useEffect(() => {
        (async () => {
            wechatUsers = undefined;
            files = [];
            load.open();
            if (window._courseEditPageSharedState && window._courseEditPageSharedState.wechatUsers) {
                wechatUsers = window._courseEditPageSharedState.wechatUsers;
            }
            course = await smartGetCourseInfo(id);
            load.close();

            const producedThumbnail = (file) => {
                if (file.type==='application/pdf') return '/pdf.png';
                else if (file.type==='video/mp4') return '/video.png';
                else return file.thumbnailUrl;
            };
            const initFiles = course.files.map(file => ({
                ...file,
                thumbnail: producedThumbnail(file)
            }));

            setFiles([...initFiles]);
            setCoverImage(course.coverImage);
            setCoverImageThumbnail(course.coverThumbnail);
            setProjectTitle(course.courseName);
            setDate(course.endTime || null);

            course.description && setDescriptionText(course.description);
            setDisableEdit(course.courseStatus !== 'draft');
            const sideBar = window._courseViewPageSideBarController;
            if ( sideBar ) {
                const { setCourseStatus, setCanDeploy, setCanReDeploy, setMemberNum } = sideBar;

                setCourseStatus(course.courseStatus);
                if (wechatUsers && wechatUsers.userlist) {
                    setMemberNum(wechatUsers.userlist.filter(user => user.selected === true).length);
                } else {
                    setMemberNum(course.members.length);
                }
                setCanDeploy(!!(course.courseStatus === 'draft'
                    && course.description.length !== 0
                    && course.members.length !== 0
                ));
                setCanReDeploy(!!(course.courseStatus === 'deployed'
                    && wechatUsers
                    && wechatUsers.userlist.filter(user => user.selected === true && user.selectDisable !== true).length > 0
                ));
                sideBar.handlePublishCourse = (...args) => currentFunctions.current.handlePublishCourse(...args);
                sideBar.handleSaveCourse = (...args) => currentFunctions.current.handleSaveCourse(...args);
                sideBar.handleCopyCourse = (...args) => currentFunctions.current.handleCopyCourse(...args);
                sideBar.switchTabHook = (...args) => currentFunctions.current.handleSaveCourseSharedState(...args);
                sideBar.handleReDeploy = (...args) => currentFunctions.current.handleReDeployCourse(...args);
                setCanDeployRef.current = sideBar.setCanDeploy;
            }
        })();
    }, []);

    const saveProjectTitle = async () => {
        load.open();
        await API.setProjectTitle(projectTitle);
        load.close();
        setProjectTitleEditable(false);
    };

    const editProjectTitle = () => {
        setProjectTitleEditable(true);
    };

    const handleDescriptionChange = (text) => {
        text = text.substring(0, maxDescriptionLength);
        setDescriptionText(text);
    };

    const handleSaveCoverImage = async (url, thumbnailUrl) => {
        load.open();
        await updateCourseInfo({
            courseId: id,
            coverImage: url,
            coverThumbnail: thumbnailUrl,
        });
        load.close();
    };

    const handleSaveFiles = async () => {
        load.open();
        await updateCourseInfo({
            courseId: id,
            files: files.map(file => ({
                id: file.id,
                name: file.name,
                type: file.type,
                isMultipleFiles: file.isMultipleFiles,
                images: file.images,
                url: file.url,
                key: file.key,
                thumbnail: file.thumbnailUrl,
                thumbnailUrl: file.thumbnailUrl,
                thumbnailKey: file.thumbnailKey,
                deleteKeys: file.deleteKeys,
            }))
        });
        load.close();
    };

    const handleDropFilesUpload = fileArr => {
        fileArr.forEach(async (file) => {
            // 创建 loading 对象
            const blob = await help.fileToBlob(file.src.file);

            let bgUrl;
            if (file.type === "application/pdf") {
                bgUrl = '/pdf.png';
            } else if (file.type === "video/mp4") {
                bgUrl = '/video.png';
            } else {
                bgUrl = URL.createObjectURL(blob);
            }

            let newFile = {
                ...file,
                loading: true,
                thumbnail: bgUrl
            };
            setFiles([...files, newFile]);

            // 文件上传 S3
            const signedUrl = await getS3SignUrl({ id: file.id, fileName: file.name, courseName: projectTitle });
            await uploadFileToS3({ blob, signedUrl });

            let thumbnailCanvas, thumbnailBlob, thumbnailSignedUrl;
            if ((file.type.startsWith('image'))) {
                // 生成缩略图上传 S3
                thumbnailCanvas = await help.resizeImage(bgUrl, 100, 100);
                thumbnailBlob = await new Promise((r) => thumbnailCanvas.toBlob(r));
                if (thumbnailBlob.size > blob.size) {
                    thumbnailSignedUrl = signedUrl;
                } else {
                    thumbnailSignedUrl = await getS3SignUrl({ id: file.id, fileName: file.name + '_thumbnail.png', courseName: projectTitle});
                    await uploadFileToS3({ blob: thumbnailBlob, signedUrl: thumbnailSignedUrl });
                }
            }

            // 生成入库对象
            let parseSignedUrl = Url(signedUrl);
            const fileUrl = parseSignedUrl.origin + parseSignedUrl.pathname;
            const fileKey = parseSignedUrl.pathname.replace(/^\//, '');

            if (file.type === "application/pdf") {
                const images = await cutPdf({ fileName: file.name, fileUrl, courseId: id });
                const imageKeys = images.map(img => Url(img).pathname.replace(/^\//, ''));
                newFile.images = images;
                newFile.deleteKeys = [fileKey, ...imageKeys];
                newFile.isMultipleFiles = true;

            } else if (file.type === "video/mp4") {
                newFile.deleteKeys = [fileKey];
                newFile.isMultipleFiles = false;

            } else {
                const parseThumbnailSignedUrl = Url(thumbnailSignedUrl);
                const thumbnailUrl = parseThumbnailSignedUrl.origin + parseThumbnailSignedUrl.pathname;
                const thumbnailKey = parseThumbnailSignedUrl.pathname.replace(/^\//, '');
                newFile.thumbnailUrl = thumbnailUrl;
                newFile.thumbnailKey = thumbnailKey;
                newFile.deleteKeys = fileKey !== thumbnailKey ? [fileKey, thumbnailKey] : [fileKey];
                newFile.isMultipleFiles = false;
            }

            newFile.url = fileUrl;
            newFile.key = fileKey;
            newFile.loading = false;

            setFiles([...files]);
            await handleSaveFiles();
        });
    };

    const handleDropCoverImageUpload = async (file) => {
        const cover = file[0];
        //生成loading文件
        const blob = await help.fileToBlob(cover.src.file);
        const bgUrl = URL.createObjectURL(blob);
        setCoverImageThumbnail(bgUrl);

        // 文件上传 S3
        const signedUrl = await getS3SignUrl({ id: cover.id, fileName: cover.name, courseName: projectTitle });
        await uploadFileToS3({ blob, signedUrl });
        const parseSignedUrl = Url(signedUrl);
        const fileUrl = parseSignedUrl.origin + parseSignedUrl.pathname;

        // 生成缩略图
        const thumbnailCanvas = await help.resizeImage(bgUrl, 100, 100);
        const thumbnailBlob = await new Promise((r) => thumbnailCanvas.toBlob(r));
        let thumbnailUrl;
        if (thumbnailBlob.size > blob.size) {
            thumbnailUrl = fileUrl;
        } else {
            // 上传 S3
            const thumbnailSignedUrl = await getS3SignUrl({ id: cover.id, fileName: cover.name + '_thumbnail.png', courseName: projectTitle});
            await uploadFileToS3({ blob: thumbnailBlob, signedUrl: thumbnailSignedUrl });
            const parseThumbnailSignedUrl = Url(thumbnailSignedUrl);
            thumbnailUrl = parseThumbnailSignedUrl.origin + parseThumbnailSignedUrl.pathname;
        }

        // 刷新状态
        setCoverImageThumbnail(thumbnailUrl);
        setCoverImage(fileUrl);

        await handleSaveCoverImage(fileUrl, thumbnailUrl);
    };

    const handleDeleteFile = async (item) => {
        const newFiles = files.filter(file => file.id !== item.id );
        setFiles(newFiles);
        await deleteFileFromS3(item.deleteKeys);
        await handleSaveFiles();
    };




    return (
        <>
            <div className={classes.firstRowContainer}>
                <Card classes={classes.firstRowCard}>
                    <p className={classes.cardTitle}>项目名称*</p>
                    <div className={classes.courseTitleContainer}>
                        {
                            projectTitleEditable ?
                                <>
                                    <Input 
                                    className={classes.editCourseTitleInput} 
                                    value={projectTitle} 
                                    onChange={(event) => { 
                                        (event.target.value.length <=15)
                                        ? setProjectTitle(event.target.value)
                                        : setProjectTitle(event.target.value.substring(0, 15));
                                    }} 
                                    />
                                    <Button disabled={projectTitle.length===0} className={classes.saveButton} onClick={saveProjectTitle} variant="contained" color="primary">保存</Button>
                                </>
                                :
                                <>
                                    <span>{projectTitle}</span>
                                    <BorderColorIcon onClick={editProjectTitle} className={disableEdit ? classes.hide : ''} style={{marginLeft: "10px"}}/>
                                </>
                        }
                    </div>
                </Card>
                <div style={{width: 12}}/>
                <Card classes={classes.firstRowCard}>
                    <Grid
                        container
                        direction="row"
                    >
                        <Grid item xs={9}>
                            <p className={classes.cardTitle}>封面图</p>
                            {/*<div className={disableEdit ? classes.hide : ''}>*/}
                                <p className={classes.describeText}>点击或拖拽上传</p>
                                <p className={classes.describeText}>图片仅支持 jpg, jpeg, png, 图片大小 &lt; 1MB</p>
                                <p className={classes.describeText}>若放弃自定义上传，则为系统默认图片</p>
                            {/*</div>*/}
                        </Grid>
                        <Grid item xs={3} >
                            <Files
                                maxSize="1mb"
                                accept={["image/png", "image/jpeg"]}
                                onSuccess={handleDropCoverImageUpload}
                                onError={error => {
                                    setErrorFiles([...errorFiles, ...error]);
                                    console.log(error);
                                    if (error[0].type === "maxSizeExceeded") {
                                        toast.error('单个封面图不得大于 1MB');
                                    }
                                }}
                            >
                                {({ browseFiles, getDropZoneProps }) => (
                                    <>
                                        {!disableEdit &&
                                            <div {...getDropZoneProps({})}
                                                className={classes.uploadContainer}
                                                style={{ backgroundImage: coverImageThumbnail ? `url("${coverImageThumbnail}")`: 'url(/defaultCoverImage.png)'}}
                                            >
                                                <IconButton onClick={browseFiles}>
                                                    <AddIcon fontSize='large'/>
                                                </IconButton>
                                            </div>
                                        }
                                    {/* <button onClick={() => console.log(coverImage)}>debug</button> */}
                                    </>
                                )}
                            </Files>
                        </Grid>
                    </Grid>
                </Card>
            </div>
            <Card classes={classes.secondRowCard}>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                >
                    <Grid item xs={6}>
                        <p className={classes.cardTitle}>课程描述*</p>
                        <TextField
                            style={{width: "400px"}}
                            multiline
                            rows={4}
                            value={descriptionText}
                            onChange={(event) => {
                                handleDescriptionChange(event.target.value);
                                setCanDeployRef.current(!!(course.courseStatus === 'draft'
                                    && event.target.value.length!==0
                                    && wechatUsers
                                    && wechatUsers.userlist.filter(user => user.selected === true).length > 0
                                ));
                            }}
                            variant="outlined"
                            disabled={disableEdit}
                            InputProps={{
                                endAdornment:
                                    <span style={{
                                        position: "relative",
                                        top: "40px",
                                        color: "#D3D3D3",
                                        fontSize: "8px"
                                    }}>
                                        {`${descriptionText.length}/${maxDescriptionLength}`}
                                    </span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 39 }}>
                        <p className={classes.cardTitle}>完成时间</p>
                        <p className={`${classes.describeText} ${classes.textMargin}`}>设定此课程的结束日期和时间</p>
                        <div className={classes.pickerContainer}>
                            <DatePicker
                                value={date || null}
                                onChange={setDate}
                                disabled={disableEdit}
                            />
                            <TimePicker
                                emptyLabel="00:00:00"
                                value={date || null}
                                onChange={setDate}
                                disabled={disableEdit}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Card>
            <Card classes={classes.thirdRowCard}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    { !disableEdit &&
                        <>
                            <p className={classes.cardTitle}>上传素材</p>
                            <p className={classes.describeText}>上传素材支持 PNG, JPG, JPEG, PDF, 视频</p>
                            <p className={classes.describeText}>单个文件不得大于 20MB</p>
                        </>
                    }
                    <Files
                        multipleMaxSize="1tb"
                        multiple={true}
                        maxSize="20mb"
                        accept={["application/pdf", "image/jpeg", "image/png", "video/mp4"]}
                        onSuccess={handleDropFilesUpload}
                        onError={error => {
                            setErrorFiles([...errorFiles, ...error]);
                            console.log(error);
                            toast.error(error);
                            if (error[0].type === "maxSizeExceeded") {
                                toast.error('单个文件不得大于 20MB');
                            }
                        }}
                    >
                        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
                            <>
                            {!disableEdit &&
                                <>
                                    <OperationButton rootStyle={classes.operationBtnRoot} onClick={browseFiles}>选择文件夹上传</OperationButton>
                                    <div {...getDropZoneProps({})} className={classes.fileUploadArea}>
                                        <div
                                            className={`${classes.fileUploadText} ${dropText === '松开鼠标开始上传' ? classes.dropFileAreaActive : null}`}
                                            onDragEnter={() => setDropText('松开鼠标开始上传')}
                                            onDragLeave={() => setDropText('或将文件拖拽至此处')}
                                            onDrop={() => setDropText('或将文件拖拽至此处')}
                                        >
                                            <span className={classes.dropText}>{dropText}</span>
                                        </div>
                                    </div>
                                </>
                            }

                                {/* <button onClick={() => {setFiles([]); setErrorFiles([]);}}>clean files</button> */}
                                {/* <button onClick={() => console.log(files)}>debug</button> */}

                                <div className={classes.uploadedFiles}>
                                    <p {...getLabelProps()} className={classes.cardTitle}>已上传文件{` (${files.length})` || null}</p>
                                    {course && course.courseStatus === 'draft' && <p className={classes.describeText}>可拖拽文件更改顺序，或点击图片右上角'x'删除文件</p>}
                                    <ReactSortable list={files} setList={setFiles} sort={!!(course && course.courseStatus === 'draft')} key={course && course.courseStatus} >
                                        {files.map((item) => (
                                            <div
                                                className={item.loading ? `${classes.fileIcon} ${classes.fileIconLoading}` : classes.fileIcon}
                                                key={item.id}
                                                style={{ backgroundImage: `url("${item.thumbnail}")`}}
                                            >
                                                <a href={item.url} target="_Blank" rel="noreferrer" title={item.name} className={classes.previewLink}>
                                                    <div className={classes.fileUploaderPreviewButtonContainer}>
                                                        <span className={classes.previewButton}>预览</span>
                                                    </div>
                                                </a>
                                                {course.courseStatus === 'draft' && <ClearIcon
                                                    className={classes.clearIcon}
                                                    onClick={ () => handleDeleteFile(item) }
                                                />}
                                            </div>
                                        ))}
                                    </ReactSortable>
                                </div>
                            </>
                        )}
                    </Files>
                </Grid>
            </Card>
        </>
    );
}
