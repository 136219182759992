import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '@components/common/card';
import Header from './header';
import Body from './body';
import TablePagination from './tablePagination';

const useStyles = makeStyles({
  tableContainer: {
    boxSizing: 'border-box',
    padding: 40,
    paddingTop: 32,
    maxHeight: 980,
  },
});

const Table = ({
  title,
  courseList,
  classes,
  hasPagination,
  dataAmount,
  pageSize,
  currentPage,
  onPageChange,
  onSort
}) => {
  const styles = useStyles();

  return (
    <Card classes={`${styles.tableContainer} ${classes}`}>
      <Header title={title} onSort={onSort}/>
      <Body courseList={courseList}/>
      {hasPagination
      && (
      <TablePagination
        dataAmount={dataAmount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      )}
    </Card>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.string,
  hasPagination: PropTypes.bool,
  dataAmount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

Table.defaultProps = {
  title: null,
  children: null,
  classes: '',
  hasPagination: false,
  dataAmount: 0,
  pageSize: null,
  currentPage: null,
  onPageChange: () => {},
};

export default Table;
