import React from 'react';
import { makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  filterButtonRoot: {
    width: 100,
    height: 100,
    backgroundColor: '#EEEEEE',
    border: '2px solid',
    borderRadius: 8,
    marginRight: 8,
    marginTop: 8,
  },
  filterButtonLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  status: {
    fontWeight: 400,
    fontSize: 14
  },
  amount: {
    fontWeight: 500,
    fontSize: 28
  }
});

const statusInfo = {
  draft: { text: '草稿', color: '#CCCCCC' },
  done: { text: '已完成', color: '#ADCFF1' },
  deployed: { text: '已发布', color: '#DAE0A4' },
};

const FilterButton = ({ status, amount, selected, currentStatus, setSelected}) => {

  const themeStyles = makeStyles({
    filterButtonColor: {
      borderColor: statusInfo[status].color,
      backgroundColor: selected? statusInfo[status].color : '#EEEEEE'
    }
  });

  const classes = useStyles();
  const theme = themeStyles();
  
  return (
    <Button 
      onClick={() => currentStatus===status ? setSelected(null) : setSelected(status)} 
      classes={{root: `${classes.filterButtonRoot} ${theme.filterButtonColor}`, label: classes.filterButtonLabel}}
    >
      <Typography className={classes.status}>{statusInfo[status].text}</Typography>
      <Typography className={classes.amount}>{amount}</Typography>
    </Button>
  );
};

export default FilterButton;