export const MIN_APP_WIDTH = '1024px';
export const MAX_APP_WIDTH = '1500px';

export const LOGIN_STATE = 'GET_CODE';

export const ROUTE_PATH = {
  LOGIN: '/login',
  HOME: '/',
  VIEW_COURSE: '/course',
  PICK_MEMBER: '/pick',
};

export const UPLOAD = {
  LIMIT_SIZE: 1024 * 1024, // 1MB
  LIMIT_COUNT: 10,
};

export const API = {
  COURSE_LIST: `/${process.env.STAGE}/api/course/list`,
  COURSE_DETAILS: `/${process.env.STAGE}/api/course/detail`,
  COURSE: `/${process.env.STAGE}/api/course`,
  COURSE_DEPLOY: `/${process.env.STAGE}/api/course-deploy`,
  WECHAT_MEMBERS: `/${process.env.STAGE}/api/wechat/members`,
  WECHAT_NOTIFICATION: `/${process.env.STAGE}/api/wechat/notification`,
  S3_SIGN_URL: `/${process.env.STAGE}/api/material`,
  CUT_PDF: `/${process.env.STAGE}/api/pdfImages`,
};

export const MESSAGE = {
  COURSE_DEPLOY: {
    title: '您有一门新的课程',
    description: '请尽快完成',
    page: 'pages/home/index',
  }
};
