import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import SortButton from './sortButton';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #DDD',
    paddingBottom: 11,
  },
  titleContainer: {
    flex: 1,
    fontFamily: 'PingFang SC',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
  },
});

const Header = ({ title, onSort }) => {
  const classes = useStyles();

  const handleSetSort = (direction) => {
    if(onSort) onSort(direction);
  };

  return (
    <div className={classes.container}>
      <span className={classes.titleContainer}>
        {title || 'Customize your title'}
      </span>
      <SortButton
        text="按时间排序"
        setSort={(direction) => handleSetSort(direction)}
      />
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  onSort: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  onSort: () => {},
};

export default Header;