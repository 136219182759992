import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    height: 160,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#999999',
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const NoDataPage = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      { text }
    </div>
  );
};

NoDataPage.propTypes = {
  text: PropTypes.string,
};

NoDataPage.defaultProps = {
  text: '- 暂无数据 -',
};

export default NoDataPage;
