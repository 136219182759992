import { http } from '@util/index';
import { API } from '@constants/index';
import lodash from "lodash";

const getWechatMembers = async () => {
  const result = await http.get(API.WECHAT_MEMBERS);
  return result;
};

const smartGetWechatMembers = async () => {
  if (window._courseEditPageSharedState && window._courseEditPageSharedState.wechatUsers) {
    const wechatUsers = window._courseEditPageSharedState.wechatUsers;
    return wechatUsers;
  }
  const wechatUsers = await getWechatMembers();
  return wechatUsers;
};

const reDeployCourse = async (courseId, members) => {
  const cleanMembers = lodash.cloneDeep(members);
  cleanMemberStatus(cleanMembers);
  await http.put(API.COURSE, { courseId, members: cleanMembers });
};

const cleanMemberStatus = (members) => {
  members && members.forEach(member => {
    delete(member.selected);
    delete(member.selectDisable);
  });
};

export {
  getWechatMembers,
  smartGetWechatMembers,
  reDeployCourse,
  cleanMemberStatus,
};
