import { http } from '@util/index';
import { API } from '@constants/index';

const getCourseList = async (query) => {
  const url = API.COURSE_LIST;
  const result = await http.get(url, { params: query });
  return result;
};

const createCourse = async (courseName) => {
  const result = await http.post(API.COURSE, { courseName });
  return result.id;
};

export {
  getCourseList,
  createCourse,
};
