import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  sideNavContainer: {
    minWidth: 300,
    height: '100%',
    borderRight: '1px solid #EEEEEE',
    backgroundColor: '#FDFDFD',
    boxShadow: '3px 3px 3px 1px #D9D8D8',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default function SideNavigation({ children, className }) {
  const classes = useStyles();

  return (
    <Grid className={`${classes.sideNavContainer} ${className}`} item xs={2}>
      {children}
    </Grid>
  );
}
