import React from 'react';
import { makeStyles, Checkbox, Avatar, List, ListItem } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';
import help from '@util/help';

const useStyles = makeStyles({
  container: {
    overflow: 'auto',
    flex: 1
  },
  listItem: {
    '&.MuiListItem-gutters': {
      paddingLeft: 0
    },
    borderTop: '1px solid #eeeeee'
  },
  avatar: {
    '&.MuiAvatar-root': {
      margin: '3px 5px 3px 10px',
      borderRadius: 5,
    }
  },
  listItemTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  name: {
    fontSize: 16,
    fontWeight: 400
  },
  position: {
    fontSize: 12,
    color: '#c4c4c4',
    marginTop: 2,
  }
});

const MemberList = ({ hasCheckedBox, userList, selectedUser, setSelectedUser }) => {
  const classes = useStyles();

  const addMember = (member) => {
    setSelectedUser(new Set(selectedUser.add(member.userid)));
  };

  const deleteMember = (member) => {
    selectedUser.delete(member.userid);
    setSelectedUser(new Set(selectedUser));
  };

  const handleCheckBox = (member, add) => {
    add ? addMember(member) : deleteMember(member);
  };

  return (
    <div className={classes.container}>
      <List>
        { userList.map((member) => (
          <ListItem button className={classes.listItem} key={member.userid}>
            {
              hasCheckedBox
              &&
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleOutlineIcon />}
                color='primary'
                onChange={(event) => handleCheckBox(member, event.target.checked)}
                checked={selectedUser.has(member.userid)}
              />
            }
            <Avatar
              className={classes.avatar}
              alt={member.name}
              src={help.wechatThumbnail(member.avatar)}
            />
            <div className={classes.listItemTextContainer}>
              <span className={classes.name}>{member.name}{member.fullName&&`(${member.fullName})`}</span>
              <span className={classes.position}>{member.position}</span>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

MemberList.propTypes= {
  hasCheckedBox: PropTypes.bool
};
MemberList.defaultProps= {
  hasCheckedBox: false
};


export default MemberList;
