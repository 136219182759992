import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles({
  createButtonRoot: {
    width: 208,
    height: 50,
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 25,
    fontSize: 16,
    '&.Mui-disabled': {
      color: '#999999',
      backgroundColor: '#eeeeee'
    }
  },
  createButtonLabel: {
    display: 'flex',
    flexDirection: 'row'
  },
});

const OperationButton = ({ children, onClick, rootStyle, labelStyle, disabled }) => {
  const classes = useStyles();

  return (
    <Button 
      disabled={disabled || false}
      classes={{
        root: `${classes.createButtonRoot} ${rootStyle}`, 
        label: `${classes.createButtonLabel} ${labelStyle}`,
      }}
      onClick={onClick}
    >
      { children }
    </Button>
  );
};

export default OperationButton;