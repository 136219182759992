import SideNav from './sideNav';
import MainContent from './mainContent';
import { ROUTE_PATH } from '@constants/index';

export default {
  public: false,
  path: ROUTE_PATH.HOME,
  SideNav,
  MainContent,
};
